import React from 'react';
import { Box, Flex, Text, Link, Icon } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <Box as="footer" bg="gray.800" color="white" p="8">
      <Flex justify="space-between" align="center" flexWrap="wrap">
        <Box flex="1" mb="4">
          <Text fontSize="lg" fontWeight="bold" mb="2">
            Quick Links
          </Text>
          <Link href="/" display="block" mb="2">
            Home
          </Link>
          <Link href="#" display="block" mb="2">
            Services
          </Link>
          <Link href="/aboutus" display="block" mb="2">
            About Us
          </Link>
          <Link href='/contactus' display="block" mb="2">
            Contact Us
          </Link>
        </Box>

        <Box flex="1" mb="4">
          <Text fontSize="lg" fontWeight="bold" mb="2">
            Contact Us
          </Text>
          <Text mb="2">Triveni Nagar 2</Text>
          <Text mb="2">Lucknow, 226020</Text>
          <Text mb="2">Email: vaibhavA1234v@gmail.com</Text>
          <Text mb="2">Phone: +91-9336566380</Text>
        </Box>

        <Box flex="1" mb="4" >
          <Text fontSize="lg" fontWeight="bold" mb="2" >
            Connect With Us
          </Text>
          <Flex justify={"center"} alignItems={"center"} gap={"20px"}>
            <Link href="https://www.facebook.com/profile.php?id=61557129895483&mibextid=JRoKGi" mr="4" target='blank'>
              <Icon as={FaFacebook} boxSize="6" />
            </Link>
            <Link href="https://x.com/Freemaidindia?t=5xBRWSKvPIrPUwkAYLPA6g&s=09" mr="4" target='blank'>
              <Icon as={FaTwitter} boxSize="6" />
            </Link>
            <Link href="https://www.instagram.com/freemaid.ai?igsh=MWhua2thamZkbWZ3bg==" mr="4" target='blank'>
              <Icon as={FaInstagram} boxSize="6" />
            </Link>
            <Link href="https://www.linkedin.com/company/freemaid/" target='blank'>
              <Icon as={FaLinkedin} boxSize="6" />
            </Link>
          </Flex>
        </Box>
      </Flex>

      <Box mt="8" textAlign="center">
        <Text fontSize="sm">&copy; 2024 Freemaid. All rights reserved.</Text>
      </Box>
    </Box>
  );
};

export default Footer;
