import React from 'react'
import CommonForm from '../components/CommonForm'

const HouseMaid = () => {
  return (
    <>
    <CommonForm
    heading={"House Maid"}
    />
    </>
  )
}

export default HouseMaid