import React from 'react'
import CommonForm from '../components/CommonForm'

const CookingMaid = () => {
  return (
    <>
    <CommonForm
    heading={"Cooking Maid"}
    />
    </>
  )
}

export default CookingMaid