import React from 'react'
import CommonForm from '../components/CommonForm'

const BabyCareTaker = () => {
  return (
    <>
    <CommonForm
    heading={"Baby Care Taker"}
    />
    </>
  )
}

export default BabyCareTaker