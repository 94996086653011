import React from 'react';
import "../styles/FounderFooter.css"
import { Box } from "@chakra-ui/react"
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { BsLinkedin } from "react-icons/bs";
import { IoLogoGithub } from "react-icons/io5";

export default function FounderFooter() {
    return (
        <footer class="footer">
            <Box class="footer-item">
                <a href="https://x.com/Freemaidindia?t=5xBRWSKvPIrPUwkAYLPA6g&s=09" target="_blank"><FaXTwitter fill='white' /></a>
            </Box>
            <Box>
                <a href="https://www.instagram.com/freemaid.ai?igsh=MWhua2thamZkbWZ3bg=="><FaInstagramSquare fill='white' /></a>
            </Box>
            <Box>
                <a href="https://www.facebook.com/profile.php?id=61557129895483&mibextid=JRoKGi"><FaFacebook fill='white' /></a>
            </Box>
            <Box>
                <a href="https://www.linkedin.com/company/freemaid/" target="_blank"><BsLinkedin fill='white' /></a>
            </Box>
            <Box>
                <a href="#" target="_blank"><IoLogoGithub fill='white' /></a>
            </Box>
        </footer>
    )
}