import React from 'react';
import { Box, Grid, GridItem, Image, Text } from '@chakra-ui/react';

// Data for the highlight sections
const highlightSections = [
  {
    title: 'Quality Service',
    description: 'We are committed to providing the highest quality of service to our clients.',
    image: 'https://via.placeholder.com/300x150',
  },
  {
    title: 'Trusted Experts',
    description: 'Our team consists of experienced professionals who are experts in their fields.',
    image: 'https://via.placeholder.com/300x150',
  },
  {
    title: 'Customer Satisfaction',
    description: 'We prioritize customer satisfaction and work tirelessly to ensure it.',
    image: 'https://via.placeholder.com/300x150',
  },
];

const HighlightSection = () => {
  return (
    <Box py={8} px={4} width={"75%"} margin={"auto"} gap={"30px"}>
      <Text fontSize="3xl" mb={4} textAlign="center" fontWeight="bold" marginBottom={"40px"} color={"#313638"}>
        Highlights <Text display={"inline"} color='#ffc91c' fontSize={"4xl"}>@Freemaid</Text>
      </Text>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(6, 1fr)' }} gap={8}>
        <Box gridArea={"1/1/1/3"} >
          <Image src='https://pinkcleanindia.com/wp-content/uploads/2023/11/10003-768x768.jpg' aspectRatio={"10/10"}></Image>
        </Box>
        <Box>
          <Image src='https://blog.springworks.in/wp-content/uploads/2020/05/Blog-10-Full_screen-1-1.png' aspectRatio={"5/10"}></Image>
        </Box>
        <Box gridArea={"1/4/1/6"}>
          <Image src='https://insights.workwave.com/wp-content/uploads/2019/07/iStock-906777508-e1564417323957.jpg' aspectRatio={"9.9/10"}></Image>
        </Box>
        <Box>
          <Image src='https://5.imimg.com/data5/SELLER/Default/2022/8/EP/RZ/YX/158697130/home-cleaning-service-500x500.jpg' aspectRatio={"4.6/10"}></Image>
        </Box>
        <Box>
          <Image src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYuAQqiXxR79ODMxFg3QTypyZ9QL1kPBMvZEMfoTdGW9573rWHGfI3Lpwlx0aTnH8IC0Y&usqp=CAU' aspectRatio={"6/10"}></Image>
        </Box>
        <Box gridArea={"2/2/3/4"}>
          <Image src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmIDm94k12N-GWFOLWITBnLEBOtsgy_TimLCzh9G8f7yHViA8W9uh9jDiG1NO_rWlnoLw&usqp=CAU' aspectRatio={"8/10"}></Image>
        </Box>
        <Box>
          <Image src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSylma9Qu7oRreKpbS3lHzz9b91KeuYAWr6iiev9xEJJhfvWZShnZxr0THsCRpvzlMB5P8&usqp=CAU'></Image>
        </Box>
        <Box gridArea={"2/5/3/7"}>
          <Image src='https://handrcleaning.com/wp-content/uploads/2022/08/c4r-2022-logo.png' aspectRatio={"12/10"}></Image>
        </Box>
      </Grid>
    </Box>
  );
};

export default HighlightSection;
