import React from 'react';
import { Box, Flex, Text, Heading } from '@chakra-ui/react';
import RatingCard from './RatingCard';
import "../styles/ServiceCards.css"

const services = [
  { name: 'Domestic help', rating: 5, image: "https://media.istockphoto.com/id/1487852703/photo/male-and-female-staff-cleaning-customers-homes.webp?b=1&s=170667a&w=0&k=20&c=DcHb-Ovmk7RETLRX-X-kfHdUYxJ64VaIhqBTN3Y9B3c=" },
  { name: 'Cooks', rating: 4, image: "https://www.shutterstock.com/image-photo/young-smiling-waiter-chef-cleaner-600nw-75574186.jpg" },
  { name: 'Baby sitter / Japa', rating: 3.5, image: "https://img.freepik.com/free-photo/professional-cleaning-service-people-working-together-office_23-2150520610.jpg" },
  { name: 'All-Rounder', rating: 4, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi_Mz6lX-fI-DZ0bmI2sfeRQQt3ELB8nhprg&usqp=CAU" },
  { name: '24 Hrs-Live In', rating: 3.5, image: "https://media.istockphoto.com/id/1297677804/photo/housemaid-cleans-the-table-mop-and-blue-bucket-with-the-detergents-in-the-background.jpg?s=612x612&w=0&k=20&c=RF_e5FgbMRzTRXYJtDMvKa0IsAmbsGkVYST7HkpFL4M=" }
];

const ServiceCards = () => {
  return (
    <>
      <Box>
        <Heading fontSize={"30px"} fontWeight={"500"} textAlign={"center"} marginBottom={"20px"}>Our Featured Services</Heading>
        <Heading fontSize={"30px"} fontWeight={"500"} textAlign={"center"}>Hire professionals,</Heading>
        <Heading fontSize={"30px"} fontWeight={"500"} width={"50%"} textAlign={"center"} marginBottom={"20px"} margin={"auto"}>Experienced specifically for your needs</Heading>
        <Text marginTop={"20px"} marginBottom={"20px"}>Freemaid offers completely certified workers with an complimentary replacement policy</Text>
      </Box>
      <Box id='service-card' width={"72%"} margin={"auto"} display={"flex"} justifyContent={"space-around"} >
        {services.map((service, index) => <RatingCard key={index} service={service} />)}
      </Box>
    </>
  );
};

export default ServiceCards;
