import { useState } from 'react'
import mail from '../assets/mail-142.svg'
import '../styles/AboutFounder.css'
import FounderFooter from './FounderFooter.jsx'
import {Box, Image, Heading, Text, Button} from "@chakra-ui/react"
function AboutFounder() {
  const [count, setCount] = useState(0)

  return (
    <>
      <Box className="main-card">
        <Box class="card w317">
            <Box className='avatar-card'>
              <Image src={"https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"} className="avatar" alt="React logo" />
            </Box>
            <Box>
              <Heading className="name">Vaibhav Shrivastva</Heading>
              <Heading className="designation">Founder</Heading>
              <Heading className="website"><a href="https://freemaid.com/" target="_blank">freemiad.com</a></Heading>
              <Button><a href="mailto:vaibhavA123v@gmail.com.com"><Image src={mail} className="emailIcon" /><span class="btntext">Email</span></a></Button>
              <Heading class="remove-space">About</Heading>
              <Text>I am founder of freemaid</Text>
              <Heading class="remove-space">Interests</Heading>
              <Text class="remove-space">Food expert. Music scholar. Reader. Blogger.</Text>
            </Box>
            <FounderFooter />
          </Box>
      </Box>
    </>
  )
}

export default AboutFounder
