import React from 'react';
import { Box, Text } from "@chakra-ui/react";


const ContactCard = ({ icon, label, desc }) => {
  return (
    <Box w="350px" h="50px" display={"flex"} marginBottom={"10px"}>
      <Box className='icon-container' padding={"10px"} backgroundColor={"#383838"} borderRadius={"15px"}>{icon}</Box>
      <Box textAlign={"left"} marginLeft={"8px"}>
        <Text fontSize={"14px"}>{label}</Text>
        <Text fontSize={"16px"} fontWeight={"bold"} color={"#313638"}>{desc}</Text>
      </Box>
    </Box>
  )
}

export default ContactCard