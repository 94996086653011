import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    Button,
    Box,
    Input,
    Textarea,
    useBreakpointValue,
    Heading
} from "@chakra-ui/react"

const ContactMe = () => {


    // useEffect(() => {
    //     Aos.init({ duration: 2000 })
    // })

    return (
        <Box className="contactForm" margin={"auto"} width={"73%"} marginTop={"40px"} marginBottom={"70px"} border={"1px solid gray"} padding={"20px"} borderRadius={"10px"}>
            <Heading color={"#313638"}>Reach Out to Us</Heading>
            <form
                action="https://getform.io/f/d1c0affc-32e4-4620-91d3-5bf5ec098551"
                method="POST"
            >
                <Box display="flex" flexWrap="wrap" gap={useBreakpointValue({ base: 4, md: 8 })} padding={"10px"} width={{ base: "100%", md: "90%" }} margin={"auto"}>
                    <Box flex="1" minWidth={{ base: "100%", md: "100%" }} marginTop={"30px"} opacity={"0.6"}>
                        <Input
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            required={true}
                        />
                        <Input
                            type="email"
                            name="email"
                            placeholder="Enter Your E-mail"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            required={true}
                        />
                        <Input
                            type="text"
                            name="subject"
                            placeholder="Enter Your Subject"
                            border={"1px solid gray"}
                            required={true}
                        />
                    </Box>
                    <Box flex="1" minWidth={{ base: "100%", md: "90%" }}>
                        <Textarea
                            name="message"
                            placeholder="Enter Your Message"
                            mb={{ base: 4 }}
                            opacity={"0.6"}
                            border={"1px solid gray"}
                            required={true}
                        />
                        <Button
                            color="white"
                            type="submit"
                            backgroundColor={"#257cac"}
                            w="80%"
                            loadingText='Wait'
                            _hover={{
                                bg: 'blue.500',
                            }}
                        //   data-aos="flip-left"
                        >
                            Send Message
                        </Button>
                    </Box>
                </Box>
            </form>
        </Box>
    )
}

export default ContactMe;
