import React, { useState } from 'react';
import {
    Button,
    Box,
    Input,
    Textarea,
    useBreakpointValue,
    Heading,
    useToast
} from "@chakra-ui/react";
import "../styles/CommonForm.css";

const CommonForm = ({ heading }) => {
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [numberOfPeople, setNumberOfPeople] = useState("");
    const [numberOfFloors, setNumberOfFloors] = useState("");
    const [numberOfRooms, setNumberOfRooms] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState("");
    const toast = useToast();

    const handleForm = async () => {
        let obj = {
            name: name,
            phoneNumber: phoneNumber,
            email: email,
            address: address,
            numberOfPeople: numberOfPeople,
            numberOfFloors: numberOfFloors,
            numberOfRooms: numberOfRooms,
            message: message,
        };
        setLoading(true);
        try {
            const response = await fetch('https://freemaid.onrender.com/api/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            });

            if (response.ok) {
                const userData = await response.json();
                toast({
                    title: 'Response submitted',
                    status: 'success',
                    isClosable: true,
                    position:"top"
                });
            } else {
                toast({
                    title: 'Something went wrong',
                    status: 'warning',
                    isClosable: true,
                    position:"top"
                });
            }
        } catch (error) {
            console.error('Error during login:', error);
            toast({
                title: 'An error occurred during Response',
                status: 'error',
                isClosable: true,
                position:"top" 
            });
        } finally {
            setLoading(false);
            setName("");
            setEmail("");
            setAddress("");
            setMessage("");
            setNumberOfFloors("");
            setNumberOfPeople("");
            setNumberOfRooms("");
            setPhoneNumber("");
        }
        setName("");
        setEmail("");
        setAddress("");
        setMessage("");
        setNumberOfFloors("");
        setNumberOfPeople("");
        setNumberOfRooms("");
        setPhoneNumber("");

    }

    return (
        <>
            <Box className="contactForm" margin={"auto"} width={"60%"} marginTop={"80px"} marginBottom={"70px"} border={"1px solid gray"} padding={"20px"} borderRadius={"10px"}>
                <Heading>{heading}</Heading>
                <form
                >
                <Box display="flex" flexWrap="wrap" gap={useBreakpointValue({ base: 4, md: 8 })} padding={"10px"} width={{ base: "100%", md: "90%" }} margin={"auto"}>
                    <Box flex="1" minWidth={{ base: "100%", md: "100%" }} marginTop={"30px"} opacity={"0.6"}>
                        <Input
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Input
                            type="phonenumber"
                            name="Phone Number"
                            placeholder="Enter Your Phone Number"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <Input
                            type="email"
                            name="email"
                            placeholder="Enter Your E-mail"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Input
                            type="text"
                            name="address"
                            placeholder="Enter Your Address"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <Input
                            type="number"
                            name="Number of people in home"
                            placeholder="Number of People in Home"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setNumberOfPeople(e.target.value)}
                        />
                        <Input
                            type="number"
                            name="number"
                            placeholder="Number of Floor in Home"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setNumberOfFloors(e.target.value)}
                        />
                        <Input
                            type="number"
                            name="number"
                            placeholder="Number of Rooms in Home"
                            mb={{ base: 4 }}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setNumberOfRooms(e.target.value)}
                        />
                    </Box>
                    <Box flex="1" minWidth={{ base: "100%", md: "90%" }}>
                        <Textarea
                            name="message"
                            placeholder="Enter Your Message"
                            mb={{ base: 4 }}
                            opacity={"0.6"}
                            border={"1px solid gray"}
                            isRequired={true}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Button
                            onClick={handleForm}
                            color="white"
                            type="submit"
                            backgroundColor={"#257cac"}
                            w="80%"
                            isLoading={loading}
                            // loadingText='Wait'
                            _hover={{
                                bg: 'blue.500',
                            }}
                        //   data-aos="flip-left"
                        >
                            Send Message
                        </Button>
                    </Box>
                </Box>
                </form>
            </Box>
        </>
    )
}

export default CommonForm