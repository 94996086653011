import React from "react";
import {
  Box,
  Heading,
  Textarea,
  Input,
  Button,
  useBreakpointValue,
  Text
} from "@chakra-ui/react";
import ContactCard from "../components/ContactCard";
import { contactData } from "../assets/utils/Data.js"
// import Aos from "aos";
// import "aos/dist/aos.css";

const ContactUs = () => {
  return (
    <>
      <Heading marginTop={"80px"} color={"#313638"}>Contact Us</Heading>
      <Box display={{base:"block",md:"flex"}} justifyContent={"space-around"}>
        <Box className="contactForm" margin={"10px"} width={{base:"90%", md:"50%"}} >
          <form
            action="https://getform.io/f/d1c0affc-32e4-4620-91d3-5bf5ec098551"
            method="POST"
          >
            <Box display="flex" flexWrap="wrap" gap={useBreakpointValue({ base: 4, md: 8 })} padding={"10px"} width={{ base: "100%", md: "70%" }} margin={"auto"}>
              <Box flex="1" minWidth={{ base: "100%", md: "50%" }} marginTop={"30px"} opacity={"0.6"}>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter Your Name"
                  mb={{ base: 4 }}
                  border={"1px solid gray"}
                />
                <Input
                  type="email"
                  name="email"
                  placeholder="Enter Your E-mail"
                  mb={{ base: 4 }}
                  border={"1px solid gray"}
                />
                <Input
                  type="text"
                  name="subject"
                  placeholder="Enter Your Subject"
                  border={"1px solid gray"}
                />
              </Box>
              <Box flex="1" minWidth={{ base: "100%", md: "50%" }}>
                <Textarea
                  name="message"
                  placeholder="Enter Your Message"
                  mb={{ base: 4 }}
                  opacity={"0.6"}
                  border={"1px solid gray"}
                />
                <Button
                  backgroundColor="#3182cf"
                  color="white"
                  type="submit"
                //   data-aos="flip-left"
                >
                  Send Message
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
        <Box width={{base:"90%", md:"55%"}} margin={"auto"}>
          <Text textAlign={"left"}>Hey we are here.!</Text>
          <Heading textAlign={"left"} marginBottom={"10px"} color={"#3182cf"}>Get in touch with us</Heading>
          {
            contactData?.map((el, index) =>
              <ContactCard
                icon={el.icon}
                label={el.label}
                desc={el.desc}
              />
            )
          }
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
