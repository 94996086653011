import React from 'react';
import { Box, Text, Flex, Heading, Image, UnorderedList, ListItem } from '@chakra-ui/react';
import css from "../styles/WhyChooseUs.css"
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();

const WhyChooseUs = () => {
    return (
        <Box py={8} px={4} mt={"30px"} margin={"20px"}>

            <div class="parent">
                <div class="section-head col-sm-12">
                    <Heading color={"#313638"}><span>Why Choose</span> Us?</Heading>
                    <Text fontSize={"30px"}>At Freemaid, we understand the importance of finding reliable domestic help that fits seamlessly into your lifestyle. Here's why you should choose us:</Text>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="item" data-aos="flip-left" data-aos-duration="1000"> <span class="icon feature_box_col_one"><i class="fa fa-globe"></i></span>
                            <Heading fontSize={"25px"} color={"#313638"}>Trusted Professionals</Heading>
                            <p>We meticulously vet all maids on our platform to ensure they meet our high standards of professionalism and reliability. You can trust that the individuals we connect you with are experienced, background-checked, and committed to delivering exceptional service.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="item" data-aos="flip-right" data-aos-duration="1000"> <span class="icon feature_box_col_two"><i class="fa fa-anchor"></i></span>
                            <Heading fontSize={"25px"} color={"#313638"}>Personalized Matching</Heading>
                            <p>Finding the perfect match for your specific needs is our priority. Our advanced matching algorithm takes into account your preferences, schedule, and requirements to connect you with maids who are best suited to meet your expectations.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="item" data-aos="flip-left" data-aos-duration="1000"> <span class="icon feature_box_col_three"><i class="fa fa-hourglass-half"></i></span>
                            <Heading fontSize={"25px"} color={"#313638"}>Convenient Booking</Heading>
                            <p>Say goodbye to the hassle of traditional booking processes. With our user-friendly platform, you can easily browse profiles, compare ratings, and book appointments with just a few clicks. Managing your household tasks has never been easier.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="item" data-aos="flip-left" data-aos-duration="1000"> <span class="icon feature_box_col_four"><i class="fa fa-database"></i></span>
                            <Heading fontSize={"25px"} color={"#313638"}>Flexible Options</Heading>
                            <p>Whether you need daily, weekly, or occasional assistance, we offer flexible scheduling options to accommodate your busy lifestyle. You have the freedom to customize your service according to your unique needs and preferences.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="item" data-aos="flip-right" data-aos-duration="1000"> <span class="icon feature_box_col_five"><i class="fa fa-upload"></i></span>
                            <Heading fontSize={"25px"} color={"#313638"}>Peace of Mind</Heading>
                            <p>Your satisfaction and peace of mind are our top priorities. We provide dedicated customer support to address any concerns or inquiries promptly, ensuring a smooth and enjoyable experience from start to finish.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="item" data-aos="flip-left" data-aos-duration="1000"> <span class="icon feature_box_col_six"><i class="fa fa-camera"></i></span>
                            <Heading fontSize={"25px"} color={"#313638"}>Transparent Pricing</Heading>
                            <p>No hidden fees or surprises. We believe in transparency, which is why we provide upfront pricing information and clear terms of service. You can trust that you're getting fair and competitive rates for the quality of service you receive.</p>
                        </div>
                    </div>
                </div>
            </div>


        </Box>
    );
};

export default WhyChooseUs;
