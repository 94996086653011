import React from 'react';
import {Routes, Route} from "react-router-dom";
import Home from '../pages/Home';
import ContactUs from '../pages/ContactUs';
import Login from '../pages/Login';
import HouseMaid from '../pages/HouseMaid';
import CookingMaid from '../pages/CookingMaid';
import BabyCareTaker from '../pages/BabyCareTaker';
import AllRounders from '../pages/AllRounders';
import TwentyFourHours from '../pages/TwentyFourHours';
import AboutUs from '../pages/AboutUs';

const AllRoutes = () => {
  return (
    <div>
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/contactus' element={<ContactUs/>}></Route>
            <Route path='/login' element={<Login/>}></Route>
            <Route path='/housemaid' element={<HouseMaid/>}></Route>
            <Route path='/cookingmaid' element={<CookingMaid/>}></Route>
            <Route path='/babycaretaker' element={<BabyCareTaker/>}></Route>
            <Route path='/allrounders' element={<AllRounders/>}></Route>
            <Route path='/twentyfourhours' element={<TwentyFourHours/>}></Route>
            <Route path='/aboutus' element={<AboutUs/>}/>
        </Routes>
    </div>
  )
}

export default AllRoutes