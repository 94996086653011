import React, { useState } from 'react';
import {
    Heading,
    Box,
    Image,
    Text,
    Stack,

} from "@chakra-ui/react";
import "../styles/AboutUs.css";
import AboutFounder from '../components/AboutFounder';

const AboutUs = () => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Box className='about-parent'>
                <Box className='about-child' id='about' mt="40px" >
                    <Stack spacing="8">
                        <Box
                            className='about-child-one'
                            display={{ base: 'block', md: 'flex' }}
                            alignItems="center"
                            justifyContent="space-between"
                            bg="white"
                            borderRadius="lg"
                            boxShadow="xl"
                            p={{ base: '20px', md: '40px' }}
                            _hover={{
                                transform: 'translateY(-4px)',
                                transition: '0.3s',
                                boxShadow: '2xl',
                            }}
                        >
                            <Box flex={{ base: '1', md: '1' }} mb={{ base: '4', md: '0' }}>
                                <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }} mb="4">
                                    About Us
                                </Heading>
                                <Text fontSize={{ base: 'md', md: 'md' }} color="gray.700">
                                    Freemaid is revolutionary digital platform that aims to bridge the gap between housemaids and customers seeking household services.
                                </Text>
                                <Text fontSize={{ base: 'md', md: 'md' }} color="gray.700" mt="4">
                                    At Freemaid, we believe that every home needs help and we are here to help you with this.
                                    We provide services like Housemaids, Domestic Help, Cooks, All-rounders, Babysitter etc.
                                    We are for both customers and Maids.
                                </Text>
                            </Box>

                            <Box flex={{ base: '1', md: '1' }} pl={{ base: '0', md: '8' }}>
                                <Image
                                    src="https://t4.ftcdn.net/jpg/01/08/34/53/360_F_108345358_mREIebrNXJM0iuAlxvEshj1j2QrhhzpK.jpg"
                                    alt="About Us Image"
                                    borderRadius="lg"
                                    boxShadow="lg"
                                    w="full"
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Box>

                <Box mt="40px" className='about-child'>
                    <Stack spacing="8">
                        <Box
                            className='about-child-one'
                            display={{ base: 'block', md: 'flex' }}
                            alignItems="center"
                            justifyContent="space-between"
                            bg="white"
                            borderRadius="lg"
                            boxShadow="xl"
                            p={{ base: '20px', md: '40px' }}
                            _hover={{
                                transform: 'translateY(-4px)',
                                transition: '0.3s',
                                boxShadow: '2xl',
                            }}
                        >
                            <Box flex={{ base: '1', md: '1' }}>
                                <Image
                                    src="https://img.freepik.com/free-vector/business-share-market-growth-green-arrow-background_1017-37130.jpg?size=626&ext=jpg&ga=GA1.1.735520172.1711411200&semt=ais"
                                    alt="Section 3 Image"
                                    borderRadius="lg"
                                    boxShadow="lg"
                                    w="full"
                                />
                            </Box>

                            <Box flex={{ base: '1', md: '1' }} pl={{ base: '0', md: '8' }}>
                                <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }} mb="4">
                                    Market Growth
                                </Heading>
                                <Text fontSize={{ base: 'md', md: 'md' }} color="gray.700">
                                    Expected to be around USD 3.57 billion by  2032, according to Marketresearch.biz
                                    We believe that growth rate will be Projected to grow at a Compound Annual  Growth Rate (CAGR) of 7.6% from 2023 to  2032
                                    In future market for house cleaning and maid services is expected  to reach USD 491.48 billion by 2031, according to  Business Research Insights, indicating a larger  market encompassing house cleaning services  beyond just maids.
                                </Text>
                            </Box>
                        </Box>
                    </Stack>
                </Box>

                <Box mt="40px" className='about-child'>
                    <Stack spacing="8">
                        <Box
                            className='about-child-one'
                            display={{ base: 'block', md: 'flex' }}
                            alignItems="center"
                            justifyContent="space-between"
                            bg="white"
                            borderRadius="lg"
                            boxShadow="xl"
                            p={{ base: '20px', md: '40px' }}
                            _hover={{
                                transform: 'translateY(-4px)',
                                transition: '0.3s',
                                boxShadow: '2xl',
                            }}
                        >
                            <Box flex={{ base: '1', md: '1' }} mb={{ base: '4', md: '0' }}>
                                <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }} mb="4">
                                    Business
                                </Heading>
                                <Text fontSize={{ base: 'md', md: 'md' }} color="gray.700">
                                    Freemaid operates as a digital platform that connects verified housemaids with
                                    customers seeking household services. The platform provides a convenient and reliable  solution for individuals and families looking for assistance with their chores.

                                </Text>
                            </Box>

                            <Box width={"50%"} flex={{ base: '1', md: '1' }} pl={{ base: '0', md: '8' }}>
                                <Image
                                    src="https://thecleanables.com/wp-content/uploads/2023/06/how-to-start-a-cleaning-business.webp"
                                    alt="About Us Image"
                                    borderRadius="lg"
                                    boxShadow="lg"
                                    w="full"
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <AboutFounder />
        </>
    )
}

export default AboutUs