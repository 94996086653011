import React from 'react';
import { Box, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

const FaqsAccordion = () => {
  return (
    <Box py={8} px={4} w={{base:"90%", md:"70%"}} m={"auto"}>
      <Text fontSize="3xl" mb={4} textAlign="center" fontWeight="bold" color={"#313638"}>
        FAQs
      </Text>
      <Accordion defaultIndex={[0]} allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How do I find a maid that meets my specific needs?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Our platform offers a comprehensive search and filtering system that allows you to specify your requirements, such as experience level, availability, and specific tasks needed. You can also view detailed profiles and reviews to make an informed decision.

          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Are the maids on your platform background-checked and vetted?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Yes, we conduct thorough background checks and vetting processes for all maids on our platform to ensure they meet our standards of professionalism, reliability, and trustworthiness.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                What happens if I'm not satisfied with the service provided by a maid?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Your satisfaction is our priority. If you're not satisfied with the service provided by a maid, you can contact our customer support team, and we will work to resolve the issue promptly. Additionally, we offer a satisfaction guarantee to ensure you're happy with the service you receive.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How does the booking process work?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Booking a maid is simple and convenient. You can browse available maids, view their profiles and ratings, and select a time slot that works for you. Once you've made a booking, the maid will arrive at your specified location and time to provide the requested services.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Is there a minimum booking requirement?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We offer flexible booking options to accommodate your needs. Whether you need a one-time cleaning or regular assistance, there is no minimum booking requirement. You have the freedom to schedule appointments according to your bookings
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default FaqsAccordion;
