import { FiPhone } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io5";

export const contactData = [
    {
        icon: <FiPhone fontSize={"28px"} fill="#bcbcbc"/>,
        label:"Phone Number",
        desc:"+91-9336566360"
    },
    {
        icon: <CiLocationOn fontSize={"28px"} fill="#bcbcbc"/>,
        label:"Headquaters",
        desc:"Triveni Nagar 2, Lucknow, 226020"
    },
    {
        icon: <MdOutlineEmail fontSize={"28px"}fill="#bcbcbc"/>,
        label:"E-mail ID",
        desc:"vaibhavA1234v@gmail.com"
    },
    {
        icon: <IoLogoWhatsapp fontSize={"28px"}fill="#bcbcbc"/>,
        label:"Whatsapp",
        desc:"+91-9336566360"
    },

];

