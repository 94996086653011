import React from 'react'
import CommonForm from '../components/CommonForm'

const AllRounders = () => {
  return (
    <>
    <CommonForm
    heading={"All Rounders"}
    />
    </>
  )
}

export default AllRounders