import { Box, HStack, Heading, Stack, Image, Paragraph, Text, Button } from '@chakra-ui/react';
import React from 'react';
import image from "../Images/banner-bg.png";
import css from "../styles/Home.css";
import ServiceCards from '../components/ServiceCard';
import HighlightSection from '../components/HighLightSection';
import BackedOrganisation from '../components/BackedOrganisation';
import WorkerStoriesCarousel from '../components/WorkerStoriesCarousel';
import WhyChooseUs from '../components/WhyChooseUs';
import FaqsAccordion from '../components/FaqsAccordion';
import FeedbackCarousel from '../components/FeedBackCarousel';
import StoryCarousel from '../components/StoryCarousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactMe from '../components/ContactMeHome';
import GetQuery from '../components/GetQuery';

const Home = () => {
  return (
    <>
      <Box
        backgroundImage={image}
        backgroundPosition={"center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        marginBottom={"100px"}
        backgroundColor={"#c3c7cd"}
      >
        <HStack direction="row" margin={"auto"} marginTop={"60px"} className='main-container'>
          <Box id='top-left-container' width={"35%"} marginLeft={"40px"} position={"relative"} marginTop={"30px"} marginBottom={"100px"}>
            <Heading id='top-left-container-heading' width={{ base: "70%", md: "50%" }} fontSize={"50px"} letterSpacing={"2px"} lineHeight={"90px"} marginLeft={"170px"} position={"relative"} zIndex={"1"}>INDIA'S TRUSTED HOME MAKERS</Heading>
            <Box id="top-white-space" width={"250px"} height={"40px"} backgroundColor={"white"} position={"absolute"} top={"140px"} left={"165px"} zIndex={"0"}></Box>
            <Text width={"60%"} textAlign={"left"} marginLeft={"180px"}>Freemaid is the simplest way to get your life in order with the right domestic help</Text>
            <GetQuery
              text={"BOOK NOW"}
              colour={"white"}
              bg={"black"}
              colorh={"black"}
              mt={"50px"}
              display={"block"}
              heading={"BOOK NOW"}
            />
          </Box>
          <Box id='top-right-container' width={"60%"}
          // border={"1px solid green"}
          >
            <Box width={"70%"} margin={"auto"}>
              <Image src='https://broomees.com/assets/images/banner-right1.png' width={"90%"}></Image>
            </Box>
            <Box id='left-top-box' data-aos="flip=left">
              <Box width="95px" height="100%">
                <Image src='https://img.freepik.com/free-photo/afro-woman-holding-bucket-with-cleaning-items_58466-11246.jpg' width={"100%"} height={"100%"} objectFit={"cover"} borderTopLeftRadius={"8px"} borderBottomLeftRadius={"8px"}></Image>
              </Box>
              <Box>
                <h3>Babysitters</h3>
                <Text fontSize={"10px"} width={"80%"} textAlign={"left"}>Experienced babysitters and japa for all your child-care needs!</Text>
                <a href="">See more</a>
              </Box>
            </Box>
            <Box id='right-top-box'>
              <Box width="95px" height="100%">
                <Image src='https://png.pngtree.com/png-clipart/20201225/ourmid/pngtree-housekeeping-cleaner-cleaning-the-house-original-hand-drawn-cartoon-png-image_2601622.jpg' width={"100%"} height={"100%"} objectFit={"cover"} borderTopLeftRadius={"8px"} borderBottomLeftRadius={"8px"}></Image>
              </Box>
              <Box>
                <h3>Regular Cleaning</h3>
                <Text fontSize={"10px"} width={"80%"} textAlign={"left"}>Scheduled cleaning sessions to maintain cleanliness and tidiness in your home on a recurring basis</Text>
                <a href="">See more</a>
              </Box>
            </Box>
            <Box id='right-bottom-box'>
              <Box width="95px" height="100%">
                <Image src='https://w7.pngwing.com/pngs/878/268/png-transparent-woman-carrying-household-cleaning-product-cleaner-maid-service-cleaning-domestic-worker-housekeeping-cleaning-cook-small-appliance-commercial-cleaning.png' width={"100%"} height={"100%"} objectFit={"cover"} borderTopLeftRadius={"8px"} borderBottomLeftRadius={"8px"}></Image>
              </Box>
              <Box>
                <h3>Deep Cleaning</h3>
                <Text fontSize={"10px"} width={"80%"} textAlign={"left"}>Thorough and comprehensive cleaning to tackle dirt, grime, and buildup in hard-to-reach areas, leaving your home spotless.
                </Text>
                <a href="">See more</a>
              </Box>
            </Box>
            <Box id='left-bottom-box'>
              <Box width="95px" height="100%">
                <Image src='https://img.freepik.com/free-photo/full-length-photo-attractive-brunette-maid-uniform-leaned-mop-while-standing_171337-18572.jpg' width={"100%"} height={"100%"} objectFit={"cover"} borderTopLeftRadius={"8px"} borderBottomLeftRadius={"8px"}></Image>
              </Box>
              <Box>
                <h3>One-Time Cleaning</h3>
                <Text fontSize={"10px"} width={"80%"} textAlign={"left"}>On-demand cleaning services for special occasions, events, or when you need an extra hand to spruce up your home.</Text>
                <a href="">See more</a>
              </Box>
            </Box>
          </Box>
        </HStack>
      </Box>
      <ServiceCards />
      <HighlightSection />
      {/* <BackedOrganisation /> */}
      <StoryCarousel />
      <WhyChooseUs />
      <FaqsAccordion />
      <FeedbackCarousel />
      <ContactMe />
    </>
  )
}

export default Home