import React, { useState } from 'react';
import { Flex, Image, Text, Box, Heading } from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../styles/StoryCarousel.css";
import { ReactComponent as LeftQuote } from "../assets/svgs/quote-left.svg";


const StoryCarousel = () => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box textAlign="center" width={{ base: "90%", md: "70%" }} margin={"auto"} marginTop={"80px"}>
      <Slider {...settings}>
        <Box
          bgImage="url('')"
          bgSize="cover"
          bgPosition="center"
          // h={{ base: '300px', md: '400px' }}
          objectFit="cover"
          position="relative"
        >
          <div className="testimonialContainer">
            <div className="testimonialHeader">
              <div className="imageContainer">
                <img
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "50%" }}
                  src={"https://media.istockphoto.com/id/1278778757/photo/profile-picture-of-smiling-indian-female-employee.jpg?s=612x612&w=0&k=20&c=W-Xok-atqe48MF30IH5lr9pM9HjadVrqBqmPcYjAGQM="}
                  alt="krittibas"
                />
              </div>
              <div className="nameContainer">
                <h4>Amrita Goswami</h4>
                <p>Baby Carer</p>
              </div>
              <div className="iconContainer">
                <LeftQuote style={{ fill: "grey" }} className="icon" />
              </div>
            </div>
            <div className="testimonialBody">
              <p className="testimonialBodyText">
                "I am really grateful to Freemaid for finding a baby carer for
                my child. Happy with the woman who was given to me. The Freemaid
                team was extremely cooperative. It is a great portal for working
                parents like us who can easily search and find any kind of
                domestic help."
              </p>
            </div>
          </div>
        </Box>
        <Box
          bgImage="url('')"
          bgSize="cover"
          bgPosition="center"
          h={{ base: '300px', md: '400px' }}
          objectFit="cover"
          position="relative"
        >
          <div className="testimonialContainer">
            <div className="testimonialHeader">
              <div className="imageContainer">
                <img
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "70%" }}
                  src={"https://media.istockphoto.com/id/1278778757/photo/profile-picture-of-smiling-indian-female-employee.jpg?s=612x612&w=0&k=20&c=W-Xok-atqe48MF30IH5lr9pM9HjadVrqBqmPcYjAGQM="}
                  alt="krittibas"
                />
              </div>
              <div className="nameContainer">
                <h4>Amrita Goswami</h4>
                <p>Baby Carer</p>
              </div>
              <div className="iconContainer">
                <LeftQuote style={{ fill: "grey" }} className="icon" />
              </div>
            </div>
            <div className="testimonialBody">
              <p className="testimonialBodyText">
                "I am really grateful to Freemaid for finding a baby carer for
                my child. Happy with the woman who was given to me. The Freemaid
                team was extremely cooperative. It is a great portal for working
                parents like us who can easily search and find any kind of
                domestic help."
              </p>
            </div>
          </div>
        </Box>
        <Box
          bgImage="url('')"
          bgSize="cover"
          bgPosition="center"
          h={{ base: '300px', md: '400px' }}
          objectFit="cover"
          position="relative"
        >
          <div className="testimonialContainer">
            <div className="testimonialHeader">
              <div className="imageContainer">
                <img
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "70%" }}
                  src={"https://media.istockphoto.com/id/1278778757/photo/profile-picture-of-smiling-indian-female-employee.jpg?s=612x612&w=0&k=20&c=W-Xok-atqe48MF30IH5lr9pM9HjadVrqBqmPcYjAGQM="}
                  alt="krittibas"
                />
              </div>
              <div className="nameContainer">
                <h4>Amrita Goswami</h4>
                <p>Baby Carer</p>
              </div>
              <div className="iconContainer">
                <LeftQuote style={{ fill: "grey" }} className="icon" />
              </div>
            </div>
            <div className="testimonialBody">
              <p className="testimonialBodyText">
                "I am really grateful to Freemaid for finding a baby carer for
                my child. Happy with the woman who was given to me. The Freemaid
                team was extremely cooperative. It is a great portal for working
                parents like us who can easily search and find any kind of
                domestic help."
              </p>
            </div>
          </div>
        </Box>
      </Slider>
      {/* Additional content goes here */}
    </Box>
  );
};

export default StoryCarousel;
