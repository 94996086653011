import React, { useState, useRef } from 'react';
import {
    ModalOverlay, Button, Modal, ModalBody, ModalFooter, ModalHeader, useDisclosure, ModalContent, ModalCloseButton, Text, Input, useToast, FormControl, FormLabel, InputGroup, InputRightElement,
    Box,
    Heading,
    Textarea,
    useBreakpointValue,
} from "@chakra-ui/react";
import SignUpModal from './SignUpModal';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import "../styles/GetQuery.css"

const GetQuery = ({text, colour, bg, bgh, colorh, mt, display, heading}) => {
    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
        />
    )

    const OverlayTwo = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />);
    const toast = useToast();
    const navigate = useNavigate();


    return (
        <Box id='query-parent' mt={mt} >
            <Button
                ml='4'
                cursor={"pointer"} as={'a'} fontSize={'sm'} fontWeight={600} color={colour} bg={bg} _hover={{
                    bg: {bgh},
                    color: {colorh}
                }}
                onClick={() => {
                    setOverlay(<OverlayTwo />)
                    onOpen()
                }}
            >
                {text}
            </Button>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                {overlay}
                <ModalContent>
                    <ModalHeader textAlign={"center"}>{heading}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box className="contactForm" margin={"10px"} width={"100%"} >
                            <form
                                action="https://getform.io/f/d1c0affc-32e4-4620-91d3-5bf5ec098551"
                                method="POST"
                            >
                                <Box display="flex" flexWrap="wrap" gap={useBreakpointValue({ base: 4, md: 8 })} padding={"10px"} width={{ base: "100%", md: "90%" }} margin={"auto"}>
                                    <Box flex="1" minWidth={{ base: "100%", md: "100%" }} marginTop={"30px"} opacity={"0.6"}>
                                        <Input
                                            type="text"
                                            name="name"
                                            placeholder="Enter Your Name"
                                            mb={{ base: 4 }}
                                            border={"1px solid gray"}
                                            required={true}
                                        />
                                        <Input
                                            type="email"
                                            name="email"
                                            placeholder="Enter Your E-mail"
                                            mb={{ base: 4 }}
                                            border={"1px solid gray"}
                                            required={true}
                                        />
                                        <Input
                                            type="text"
                                            name="subject"
                                            placeholder="Enter Your Subject"
                                            border={"1px solid gray"}
                                            required={true}
                                        />
                                    </Box>
                                    <Box flex="1" minWidth={{ base: "100%", md: "90%" }}>
                                        <Textarea
                                            name="message"
                                            placeholder="Enter Your Message"
                                            mb={{ base: 4 }}
                                            opacity={"0.6"}
                                            border={"1px solid gray"}
                                            required={true}
                                        />
                                        <Button
                                            color="white"
                                            type="submit"
                                            backgroundColor={"yellowgreen"}
                                            w="100%"
                                            loadingText='Wait'
                                            _hover={{
                                                bg: 'blue.500',
                                            }}
                                        //   data-aos="flip-left"
                                        >
                                            Send Message
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default GetQuery