import React from 'react';
import { Box, Image, Flex, Text } from '@chakra-ui/react';
import css from "../styles/RatingCard.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();

const StarRating = ({ rating }) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    const style = {
      color: i <= rating ? 'yellow' : 'gray.300',
    };
    if (i === Math.ceil(rating) && i !== rating) {
      style.clipPath = `inset(0 ${1 - (rating % 1)}em 0 0)`;
    }
    stars.push(<Box key={i} as="span" fontSize="xl" {...style}>&#9733;</Box>);
  }
  return <Flex justifyContent={"space-between"}>
    <Box>{stars}</Box>
    <Box marginTop={"5px"} fontWeight={"700"}>{rating}</Box>
  </Flex>;
};

const RatingCard = ({ service }) => {

  return (
    <>
      <Box data-aos="flip-left" data-aos-duration="1000" maxW="170px" borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
        <Box className='img-container' width={"90%"} margin={"auto"}>
          <Image id='img' src={service.image} alt={service.name} width={"100%"} marginTop={"10px"} borderRadius={"15px"} />
        </Box>
        <Box p="4">
          <Box>
            <Text fontSize="md" color="gray.600">{service.name}</Text>
          </Box>

          <Box d="flex" mt="2" alignItems="center">
            <StarRating rating={service.rating} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RatingCard;
