import React from 'react'
import CommonForm from '../components/CommonForm'

const TwentyFourHours = () => {
  return (
    <>
    <CommonForm
    heading={"24 Hours live-in"}
    />
    </>
  )
}

export default TwentyFourHours